<template>
  <div>
    <div class="title-content">
      <h2 class="section-title">下载中心</h2>
    </div>
    <div class="row">
      <div class="row_group">
        <div class="row_content" v-for="(item,index) in list" :key="index">
          <a class="row_content_down" :href="item.url">
            <img :src="require('../../assets/download/'+item.icon)" alt="">
            {{ item.name }}
            <img src="../../assets/云下载.png" alt="">
          </a>
          <!--          <div class="row_content_detail">{{ item.detail }}</div>-->
          <!--          <div class="row_content_detail">{{ item.secondDetail }}</div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeMainDownload",
  data() {
    return {
      list: [
        {
          icon: "win.png",
          name: "电脑客户端",
          detail: "此处需文字说明",
          secondDetail: "文件大小:76MB;更新时间:2022-11-23",
          url: "http://www.jmtcsoft.com/jmt.exe",
        },
        {
          icon: "win.png",
          name: "DotNet4.5.2插件",
          detail: "此处需文字说明",
          secondDetail: "文件大小:76MB;更新时间:2022-11-23",
          url: "http://www.jmtcsoft.com/n452.exe",
        },
        {
          icon: "win.png",
          name: "佳博58系列打印机驱动",
          detail: "此处需文字说明",
          secondDetail: "文件大小:76MB;更新时间:2022-11-23",
          url: "http://www.jmtcsoft.com/58.rar",
        },
        // {
        //   icon: "文档.png",
        //   name: "商品档案导入模板",
        //   detail: "此处需文字说明",
        //   secondDetail: "文件大小:76MB;更新时间:2022-11-23",
        //   url: "http://www.jmtcsoft.com/ProjectTemp.xls",
        // },
        // {
        //   icon: "文档.png",
        //   name: "商品入库导入模板",
        //   detail: "此处需文字说明",
        //   secondDetail: "文件大小:76MB;更新时间:2022-11-23",
        //   url: "http://www.jmtcsoft.com/n4.exe",
        // },
        // {
        //   icon: "文档.png",
        //   name: "Excle导入插件",
        //   detail: "此处需文字说明",
        //   secondDetail: "文件大小:76MB;更新时间:2022-11-23",
        //   url: "http://www.jmtcsoft.com/58.rar",
        // },
        // {
        //   icon: "文档.png",
        //   name: "群发短信Excel模板",
        //   detail: "此处需文字说明",
        //   secondDetail: "文件大小:76MB;更新时间:2022-11-23",
        //   url: "http://www.jmtcsoft.com/ProjectTemp.xls",
        // }
      ]
    }
  }
}
</script>

<style scoped>

/* 小屏幕手机端 */
@media (min-width: 0px) and (max-width: 768px) {
  .row_group {
    width: 80%;
    margin: 10px auto;
  }

  .row_content {
    display: block;
    width: 100%;
    margin: 10px auto;
    text-align: left;
    color: black;
    border-top: 1px dashed #000000;
    padding: 35px 0 10px 0;
  }

  .row_content .row_content_down {
    display: block;
    vertical-align: middle;
    font-size: 18px;
    font-weight: 800;
    cursor: pointer;
  }

  .row_content .row_content_down:active {
    color: #e4b118;
  }

  .row_content .row_content_down img {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
  }

  .row_content_detail {
    color: rgb(51, 51, 51);
    font-size: 14px;
  }
}

/* 中等屏幕（桌面显示器，大于等于 992px） */
@media (min-width: 768px) and (max-width: 992px) {

}

/* 大屏幕（大桌面显示器，大于等于 1200px） */
@media (min-width: 992px) {
  .row_group {
    width: 80%;
    margin: 10px auto;
  }

  .row_content {
    display: block;
    width: 50%;
    margin: 10px auto;
    text-align: left;
    color: black;
    border-top: 1px dashed #000000;
    padding: 35px 0 10px 0;
  }

  .row_content .row_content_down {
    display: block;
    vertical-align: middle;
    font-size: 18px;
    font-weight: 800;
    cursor: pointer;
  }

  .row_content .row_content_down:active {
    color: #e4b118;
  }

  .row_content .row_content_down img {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
  }

  .row_content_detail {
    color: rgb(51, 51, 51);
    font-size: 14px;
  }
}

a {
  text-decoration: none;
  color: black;
}
</style>
