<template>
  <div>
    <footer id="footer">

      <div class="container">
        <div class="content" style="font-weight: 500">

          <div class="content_left" style="font-size:14px;">
            <div style="padding-bottom: 10px">
              <img src="../assets/JMLogo2.png" alt="" style="width: 200px;vertical-align: middle">
              <!--              <span>金米天成科技发展有限公司</span>-->
            </div>
            <div style="padding-bottom: 10px;cursor: pointer">
              <img style="display: inline-block;vertical-align: middle" src="http://www.jmtcsoft.com/img/JMGuoHui.png"
                   alt="">
              <div style="display: inline-block; padding-bottom: 10px; ;color:white;" @click="toGongAn">
                京公网安备11010502042532号
              </div>
              <div style="display: inline-block;  color:white;" @click="toICP">京ICP备13009499号-3</div>
            </div>
            <div style="padding-bottom: 10px ;color:white;">
              <!--              <div>公司地址</div>-->
              <div>北京市昌平区龙旗广场D座1207</div>
            </div>
            <div style="color:white;font-size:14px;padding-bottom: 10px;text-decoration: underline">
              联系我们&nbsp;18611781844
            </div>
            <!--            <div>-->
            <!--              <div>关注金米</div>-->
            <!--              <img src="../assets/微信公众号.png" alt="" style="width: 40px;padding-top: 10px">-->
            <!--            </div>-->
          </div>
          <div class="content_center">
            <ul class="content_center_ul">
              <li class="content_center_ul_li" style="color:white;">关于我们</li>
              <li class="content_center_ul_li"><a href="#CompanyInfo" v-smooth-scroll="{offset:-70}">了解茶艺人</a></li>
            </ul>
            <ul class="content_center_ul">
              <li class="content_center_ul_li" style="color:white;">产品与服务</li>
              <li class="content_center_ul_li">
                <a href="#services" v-smooth-scroll="{offset:-70}">管理系统</a>
              </li>
              <li class="content_center_ul_li">
                <a href="#services" v-smooth-scroll="{offset:-70}">人才服务</a>
              </li>
              <li class="content_center_ul_li">
                <a href="#services" v-smooth-scroll="{offset:-70}">托管运营</a>
              </li>
              <li class="content_center_ul_li">
                <a href="#services" v-smooth-scroll="{offset:-70}">茶馆集采</a>
              </li>
            </ul>
            <ul class="content_center_ul">
              <li class="content_center_ul_li" style="color:white;">
                案例与资料
              </li>
              <li class="content_center_ul_li">
                <a href="#partner" v-smooth-scroll="{offset:-70}">合作与案例</a>
              </li>
              <li class="content_center_ul_li">
                <a href="#portfolio" v-smooth-scroll="{offset:-70}">部分客户门头照</a>
              </li>
              <li class="content_center_ul_li">
                <a href="#download" v-smooth-scroll="{offset:-70}">附件下载</a>
              </li>
            </ul>
            <ul class="content_center_ul">
              <li class="content_center_ul_li" style="color:white;">服务体系</li>
              <li class="content_center_ul_li">
                <a href="#main1351" v-smooth-scroll="{offset:-70}">1351感动式服务</a>
              </li>
            </ul>
          </div>
          <div class="content_right" style="color:white;">
            <div style="font-size: 16px;font-weight: 800;padding-bottom: 20px;padding-left: 20px">扫码关注/快捷注册
            </div>
            <div style="text-align: center;display: flex;flex-wrap: wrap;justify-content: space-evenly;">
              <!--      二维码        -->
              <QRCode></QRCode>
            </div>
          </div>

        </div>


        <!--        <div class="row">-->

        <!--          <div class="col-md-6 col-md-offset-3" style="font-size: 12px !important;">-->

        <!--            <p class="mail">北京金米天成科技发展有限公司</p>-->

        <!--            <img style="display: inline-block" src="http://www.jmtcsoft.com/img/JMGuoHui.png" alt="">-->
        <!--            <p style="display: inline-block;margin-left: 10px" class="tel" @click="toBeiAn">京公网安备-->
        <!--              11010502042532号</p>-->
        <!--            <p style="margin-left: 10px">北京市昌平区龙旗广场D座1207</p>-->
        <!--            <p>京ICP备13009499号-3 </p>-->
        <!--            <span id="year">咨询电话</span> <span class="text-yellow">18611781844</span>-->

        <!--          </div>-->

        <!--        </div> &lt;!&ndash; /.row &ndash;&gt;-->

      </div> <!-- /.container -->

    </footer>
  </div>

</template>

<script>
import QRCode from "@/components/PageTools/QRCode";


export default {
  components: {
    QRCode
  },
  name: "HomeFooter",
  methods: {
    toGongAn() {
      window.open("http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502042532", '_blank');
    },
    toICP() {
      window.open("https://beian.miit.gov.cn/", '_blank');
    }
  }
}
</script>

<style scoped>


a {
  color: #ffffff;
  text-decoration: none;
}

/* 小屏幕手机端 */
@media (min-width: 0px) and (max-width: 768px) {

  .content_center {
    display: none;
  }
}


/* 大屏幕（大桌面显示器，大于等于 1200px） */
@media (min-width: 992px) {
  #footer {
    padding: 83px 0 50px;
    background: #182227;
    color: #ffffff;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
  }

  .content {
    display: flex;
    align-content: stretch;
    justify-content: center;
  }

  .content_left {
    width: 250px;
    text-align: left;
  }

  .content_center {
    width: 600px;
    font-size: 14px;
  }

  .content_center .content_center_ul {
    display: inline-block;
    width: 25%;
    vertical-align: top;
  }

  .content_center .content_center_ul_li:first-child {
    font-size: 16px;
    font-weight: 800;
    padding-bottom: 20px;
  }

  .content_center .content_center_ul_li {
    text-align: center;
    padding-bottom: 10px;
  }

  .content_right {
    width: 200px;
  }
}
</style>
